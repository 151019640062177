import { useRouter } from 'next/router';

const useSettingsOnboardingPageType = () => {
  const router = useRouter();

  let page = 'onboarding';

  if (router.asPath.includes('settings')) {
    page = 'settings';
  } else if (router.asPath.includes('optimize-account')) {
    page = 'optimize';
  } else if (router.asPath.includes('onboarding')) {
    page = 'onboarding';
  }

  return page;
};

export default useSettingsOnboardingPageType;
