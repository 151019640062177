import React from 'react';
interface Props {
  ivrCurrentCallsCounter: number;
}
const IvrCount = ({
  ivrCurrentCallsCounter
}: Props) => {
  return <div className="flex items-center justify-center" data-sentry-component="IvrCount" data-sentry-source-file="ivr-count.tsx">
      <div className="flex h-5/6 items-center justify-center rounded-md border-2 border-orange-300 px-2 text-sm text-gray-400 shadow-md">
        <span>Active IVR: </span>
        <span>{ivrCurrentCallsCounter}</span>
      </div>
    </div>;
};
export default IvrCount;