import { useCallback } from 'react';

export const usePhoneNumberFormatter = () => {
  const formatPhoneNumber = useCallback((phoneNumber: string) => {
    // Remove all non-digit characters except '+'
    const cleaned = ('' + phoneNumber).replace(/[^\d+]/g, '');

    // Check if it's a US number (starts with '+1' or has 10 digits)
    if (cleaned.startsWith('+1') || cleaned.length === 10) {
      const tenDigits = cleaned.slice(-10);
      return `(${tenDigits.slice(0, 3)}) ${tenDigits.slice(
        3,
        6
      )}-${tenDigits.slice(6)}`;
    }

    // For other international numbers
    if (cleaned.startsWith('+')) {
      const match = cleaned.match(/^\+(\d+)(\d{10})$/);
      if (match) {
        const [, countryCode, number] = match;
        return `+${countryCode} (${number.slice(0, 3)}) ${number.slice(
          3,
          6
        )}-${number.slice(6)}`;
      }
    }

    // If no formatting was possible, return the original number
    return phoneNumber;
  }, []);

  return formatPhoneNumber;
};
