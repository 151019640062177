import Image from 'next/image';
type Props = {
  welcomeConfetti: () => void;
};
const WelcomeModalStartPage = (props: Props) => {
  return <div className="relative flex h-full w-full flex-col items-center justify-center bg-cover bg-center" style={{
    backgroundImage: 'url("/assets/img/welcome-modal/confetti-bg.png")'
  }} data-sentry-component="WelcomeModalStartPage" data-sentry-source-file="welcome-modal-start-page.tsx">
      <div className="overflow-hidden rounded-t-xl bg-transparent py-8 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl rounded-md bg-[#f9fafb] p-4 shadow-md sm:text-center">
              <h2 className="text-xl font-semibold leading-7 text-orange-600">
                Congratulations!
              </h2>
              <p className="mt-2 font-proximaBold text-3xl tracking-tight text-gray-900 sm:text-4xl">
                Your account is set up and ready!
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Now let&apos;s help you start using it
              </p>
            </div>
          </div>
          <div className="relative overflow-hidden pt-16">
            <div onClick={props.welcomeConfetti} className="mx-auto flex max-w-7xl cursor-pointer justify-center px-6 lg:px-8">
              <Image src="/assets/img/welcome-modal/partying-face.png" alt="Party Emoji" width={410} height={410} priority data-sentry-element="Image" data-sentry-source-file="welcome-modal-start-page.tsx" />{' '}
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default WelcomeModalStartPage;