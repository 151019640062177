// NotificationsButton.tsx
import React, { FC, useCallback } from 'react';
import { useAppSelector } from '@/lib/state/hooks';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { BellIcon } from '@/assets/icons/bell-icon';
import { usePopover } from '@/lib/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import useNotifications from '@/lib/hooks/use-notifications';

/**
 * Description:
 * This component displays a bell icon with a badge showing the number of unread 
 * notifications. When clicked, it opens a popover containing notification details.
 * It integrates with Redux for state management and uses custom hooks for popover 
 * and notification handling.
 * 
 * Key features:
 * - Displays notification count badge
 * - Opens NotificationsPopover on click
 * - Uses Redux for state management
 * - Implements custom hooks for notifications and popover functionality
 * 
 
 */

export const NotificationsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const {
    handleRemoveOne,
    handleMarkAllAsRead,
    newEvents
  } = useNotifications();
  const counterNumber = useAppSelector(state => state.counter.value);
  const handleBellClick = useCallback(() => {
    popover.handleOpen();
  }, [popover]);
  return <>
      <Tooltip title="Notifications" data-sentry-element="Tooltip" data-sentry-source-file="notifications-button.tsx">
        <IconButton ref={popover.anchorRef} onClick={handleBellClick} data-sentry-element="IconButton" data-sentry-source-file="notifications-button.tsx">
          <Badge color="error" badgeContent={counterNumber} data-sentry-element="Badge" data-sentry-source-file="notifications-button.tsx">
            <BellIcon className="text-gray-600 hover:text-gray-700" data-sentry-element="BellIcon" data-sentry-source-file="notifications-button.tsx" />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover anchorEl={popover.anchorRef.current} notifications={newEvents} onClose={popover.handleClose} onMarkAllAsRead={handleMarkAllAsRead} onRemoveOne={handleRemoveOne} open={popover.open} data-sentry-element="NotificationsPopover" data-sentry-source-file="notifications-button.tsx" />
    </>;
};