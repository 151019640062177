import { useCallback, useEffect, useRef, useState } from 'react';
import { Call, Device } from '@twilio/voice-sdk';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import {
  updateIsCallActive,
  updateOutboundCallStatus,
  setOutboundCall,
  resetCallData,
  setActiveCall
} from '@/lib/state/slices/call-center-slice';
import {
  useClaimCallMutation,
  useHangUpCallMutation
} from '@/lib/state/services/call-center-api';
import { IncomingCallData } from '@/lib/types/rtk-types/call-center';

/**
 * useCallManagement Custom Hook
 *
 * This hook manages the core functionality of call handling in the call center application.
 * It integrates with Twilio Device and Redux to provide a centralized call management solution.
 *
 * Key features:
 * - Manages Twilio Device event listeners (incoming, connect, disconnect)
 * - Handles call state transitions (incoming, outgoing, active, null)
 * - Provides functions for call actions (accept, end, mute)
 * - Interacts with Redux to update global call state
 * - Manages API calls for claiming and hanging up calls
 *
 * @param twilioDevice - The Twilio Device instance
 *
 * @returns An object containing:
 *  - acceptCall: Function to accept an incoming call
 *  - endCall: Function to end the current call
 *  - muteCall: Function to mute/unmute the current call
 *  - currentCall: The current Twilio Call object
 *  - callStage: The current stage of the call ('incoming', 'outgoing', 'active', or null)
 *
 * Usage:
 * const { acceptCall, endCall, muteCall, callStage } = useCallManagement(twilioDevice);
 */

type CallStage = 'incoming' | 'outgoing' | 'active' | null;

export const useCallManagement = (twilioDevice: Device | null) => {
  const dispatch = useAppDispatch();
  const incomingCallData = useAppSelector(
    (state) => state.callCenter.incomingCallData
  );
  const isOutboundCall = useAppSelector(
    (state) => state.callCenter.isOutboundCall
  );

  // Ref to store the current call object
  const currentCallRef = useRef<Call | null>(null);

  // State to track call stage
  const [callStage, setCallStage] = useState<CallStage>(null);

  // RTK Query hooks for API calls
  const [claimCall] = useClaimCallMutation();
  const [hangUpCall] = useHangUpCallMutation();

  // Accept an incoming call
  const acceptCall = useCallback(
    async (callData: IncomingCallData) => {
      if (currentCallRef.current) {
        currentCallRef.current.accept();
        dispatch(updateIsCallActive(true));
        dispatch(updateOutboundCallStatus('connected'));
        dispatch(setActiveCall(incomingCallData));
        if (callData?.id) await claimCall(callData.id);
        setCallStage('active');
      }
    },
    [dispatch, claimCall, incomingCallData]
  );

  // End the current call
  const endCall = useCallback(async () => {
    if (currentCallRef.current) {
      currentCallRef.current.disconnect();
      if (incomingCallData?.id) {
        await hangUpCall(incomingCallData.id);
      }
      dispatch(resetCallData());
      currentCallRef.current = null;
      setCallStage(null);
    }
  }, [dispatch, hangUpCall, incomingCallData]);

  // Mute or unmute the current call
  const muteCall = useCallback((mute: boolean) => {
    if (currentCallRef.current) {
      currentCallRef.current.mute(mute);
    }
  }, []);

  // Handle incoming call events
  const handleIncomingCall = useCallback(
    (call: Call) => {
      currentCallRef.current = call;

      if (call.parameters.Params?.includes('CallType=outbound')) {
        // This is an outbound call that has been answered
        dispatch(setOutboundCall(call.parameters.CallSid));
        setCallStage('active');
        if (incomingCallData) {
          acceptCall(incomingCallData);
        }
      } else {
        // This is an incoming call
        setCallStage('incoming');
      }

      // Set up call-specific event listeners
      call.on('accept', () => {
        dispatch(updateIsCallActive(true));
        dispatch(updateOutboundCallStatus('connected'));
        setCallStage('active');
      });

      call.on('disconnect', () => {
        dispatch(resetCallData());
        setCallStage(null);
      });
    },
    [dispatch, acceptCall, incomingCallData]
  );

  // Handle connected call events
  const handleConnectedCall = useCallback(
    (call: Call) => {
      setCallStage('active');
      dispatch(updateIsCallActive(true));
      dispatch(updateOutboundCallStatus('connected'));
    },
    [dispatch]
  );

  // Handle disconnected call events
  const handleDisconnectedCall = useCallback(() => {
    setCallStage(null);
    dispatch(resetCallData());
  }, [dispatch]);

  // Set up Twilio device event listeners
  useEffect(() => {
    if (twilioDevice) {
      twilioDevice.on('incoming', handleIncomingCall);
      twilioDevice.on('connect', handleConnectedCall);
      twilioDevice.on('disconnect', handleDisconnectedCall);

      return () => {
        twilioDevice.off('incoming', handleIncomingCall);
        twilioDevice.off('connect', handleConnectedCall);
        twilioDevice.off('disconnect', handleDisconnectedCall);
      };
    }
  }, [
    twilioDevice,
    handleIncomingCall,
    handleConnectedCall,
    handleDisconnectedCall
  ]);

  // Effect to handle outbound call status
  useEffect(() => {
    if (isOutboundCall) {
      setCallStage('outgoing');
    }
  }, [isOutboundCall]);

  return {
    acceptCall,
    endCall,
    muteCall,
    currentCall: currentCallRef.current,
    callStage
  };
};
