// Confetti.js
import confetti from 'canvas-confetti';
interface ConfettiOptions {
  spread?: number;
  startVelocity?: number;
  decay?: number;
  scalar?: number;
}
export const welcomeConfetti = () => {
  const count = 250;
  const defaults = {
    origin: {
      y: 0.5
    }
  };
  function fire(particleRatio: number, opts: ConfettiOptions) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
      zIndex: 10000
    });
  }
  setTimeout(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
      scalar: 2,
      decay: 0.9
    });
  }, 750);
  setTimeout(() => {
    fire(0.2, {
      spread: 60,
      scalar: 2,
      decay: 0.9
    });
  }, 675);
  setTimeout(() => {
    fire(0.35, {
      spread: 100,
      scalar: 2.5,
      decay: 0.9
    });
  }, 550);
  setTimeout(() => {
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      scalar: 3,
      decay: 0.9
    });
  }, 630);
  setTimeout(() => {
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
      scalar: 2.5,
      decay: 0.9
    });
  }, 700);
};