import { useAppSelector } from '@/lib/state/hooks';
import { clearNotification } from '@/lib/state/slices/calendar-notification-slice';
import React from 'react';
import { useDispatch } from 'react-redux';
const CalendarNotificationCard: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useAppSelector(state => state.notifications.item);
  const isVisible = useAppSelector(state => state.notifications.isVisible);

  // If there's no notification or it's not visible, don't render the component
  if (!notification || !isVisible) return null;
  const {
    title,
    customer_name,
    begin_at
  } = notification;
  const handleDismiss = () => {
    dispatch(clearNotification());
  };

  // Function to format the time difference
  const formatTimeDifference = (beginAt: string) => {
    const now = new Date();
    const beginTime = new Date(beginAt);
    const diff = beginTime.getTime() - now.getTime();
    if (diff <= 0) {
      return 'Meeting time passed';
    }
    const minutes = Math.round(diff / 60000);
    if (minutes < 60) {
      return `${minutes} minutes`;
    }
    const hours = Math.round(diff / 3600000);
    if (hours < 24) {
      return `${hours} hours`;
    }
    const days = Math.round(diff / 86400000);
    return `${days} days`;
  };
  const timeUntilMeeting = formatTimeDifference(begin_at);
  return <div className="flex items-center px-4 py-1 text-center text-gray-900 border-2 border-orange-500 rounded-lg" style={{
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.3)'
  }} data-sentry-component="CalendarNotificationCard" data-sentry-source-file="calendar-notification.tsx">
      <div className="flex flex-col text-left">
        <div className="font-proximaBold">
          {title} {customer_name ? `with ${customer_name}` : ''}
        </div>
        <div className="text-xs">In {timeUntilMeeting}</div>
      </div>
      <div className="pl-8 ">
        <span style={{
        marginRight: '10px',
        cursor: 'pointer',
        color: '#007bff'
      }} onClick={handleDismiss}>
          Dismiss
        </span>
        {/* <span
          style={{ cursor: 'pointer', color: '#007bff' }}
          onClick={handleEdit}
         >
          Edit
         </span> */}
      </div>
    </div>;
};
export default CalendarNotificationCard;