import SettingsOptimizeAccount from '@/lib/types/rtk-types/settings-optimize-account';
import { api } from './api';

export const settingsOptimizeAccountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettingsOptimizeAccount: build.query<SettingsOptimizeAccount, void>({
      query: () => '/v2/store/settings_getting_started',
      providesTags: ['StoreData']
    })
  })
});

export const { useGetSettingsOptimizeAccountQuery } =
  settingsOptimizeAccountApi;
