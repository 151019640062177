import { Menu as MenuIcon } from '@/assets/icons/menu';
import { Moon as MoonIcon } from '@/assets/icons/moon';
import { Sun as SunIcon } from '@/assets/icons/sun';
import { UserButton } from '@/components/_shared/layouts/dashboard/dashboard-userbar/user-button/user-button';
import { useDarkMode } from '@/lib/hooks/use-dark-mode';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../../lib/hooks/use-auth';
import { ColorModePopover } from './color-mode-popover';
import { MessagesPopover } from './messages-popover';
import { NotificationsButton } from './notifications-button/notifications-button';
import type { FC } from 'react';
import type { AppBarProps } from '@mui/material';
import { useAppSelector } from '@/lib/state/hooks';
import DashboardUserbarCallCenterComponent from '@/components/_shared/call-center/dashboard-user-bar-call-center-component';
import IvrCount from '@/components/_shared/layouts/dashboard/dashboard-userbar/ivr-count';
import CalendarNotificationCard from '@/components/_shared/layouts/dashboard/dashboard-userbar/calendar-notification';
import useTwilioDevice from '@/lib/hooks/use-twilio-device';
interface DashboardUserbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}
const DashboardUserbarRoot = styled(AppBar)(({
  theme
}) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light' ? {
    boxShadow: theme.shadows[3]
  } : {
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    boxShadow: 'none'
  })
}));
const ColorModeButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const {
    darkMode
  } = useDarkMode();
  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };
  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };
  const [selectedTheme, setSelectedTheme] = useState(darkMode.theme);
  useEffect(() => {
    setSelectedTheme(darkMode.theme);
  }, [darkMode.theme]);
  return <>
      <Tooltip title="Color Mode" data-sentry-element="Tooltip" data-sentry-source-file="dashboard-userbar.tsx">
        <IconButton onClick={handleOpenPopover} ref={anchorRef} data-sentry-element="IconButton" data-sentry-source-file="dashboard-userbar.tsx">
          {selectedTheme === 'dark' ? <MoonIcon fontSize="small" /> : <SunIcon fontSize="small" />}
        </IconButton>
      </Tooltip>
      <ColorModePopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} data-sentry-element="ColorModePopover" data-sentry-source-file="dashboard-userbar.tsx" />
    </>;
};
const DisplayStoreName = () => {
  const store_id = useAuth()?.user?.store_id;
  const {
    data: storeData
  } = useGetStoreSettingsQuery(store_id);
  const storeName = storeData?.data?.name ?? 'Store Name';
  return <Box sx={{
    alignItems: 'center',
    display: 'flex',
    ml: 1
  }} data-sentry-element="Box" data-sentry-component="DisplayStoreName" data-sentry-source-file="dashboard-userbar.tsx">
      <Typography color="textPrimary" variant="subtitle2" data-sentry-element="Typography" data-sentry-source-file="dashboard-userbar.tsx">
        {storeName}
      </Typography>
    </Box>;
};
const MessagesButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };
  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };
  return <>
      <Tooltip title="Messages" data-sentry-element="Tooltip" data-sentry-source-file="dashboard-userbar.tsx">
        <IconButton onClick={handleOpenPopover} sx={{
        ml: 1
      }} ref={anchorRef} data-sentry-element="IconButton" data-sentry-source-file="dashboard-userbar.tsx">
          <QuestionAnswerIcon fontSize="small" data-sentry-element="QuestionAnswerIcon" data-sentry-source-file="dashboard-userbar.tsx" />
        </IconButton>
      </Tooltip>
      <MessagesPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} data-sentry-element="MessagesPopover" data-sentry-source-file="dashboard-userbar.tsx" />
    </>;
};

/* Description: This component is the userbar that appears at the top of the dashboard layout. It contains the sidebar toggle button, call center component, calendar notification card, IVR notification counter, store name, notifications button, and user button. */

export const DashboardUserbar: FC<React.PropsWithChildren<DashboardUserbarProps>> = props => {
  const {
    onOpenSidebar,
    ...other
  } = props;
  const isCallCenterActive = useAppSelector(state => state.callCenter.widgetVisibility);
  const token = useAppSelector(state => state.callCenter.twilioToken);
  const twilioDevice = useTwilioDevice(token);

  //get ivt current calls counter from redux
  const ivrCurrentCallsCounter: number = useAppSelector(state => state.callCenter.ivrCurrentCallsCounter);
  return <>
      <DashboardUserbarRoot {...other} data-sentry-element="DashboardUserbarRoot" data-sentry-source-file="dashboard-userbar.tsx">
        {/* Userbar content */}
        <Toolbar disableGutters sx={{
        minHeight: 64,
        left: 0,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }} data-sentry-element="Toolbar" data-sentry-source-file="dashboard-userbar.tsx">
          {/* Sidebar toggle button */}
          <IconButton onClick={onOpenSidebar} sx={{
          display: {
            xs: 'inline-flex',
            xl: 'inline-flex'
          }
        }} data-sentry-element="IconButton" data-sentry-source-file="dashboard-userbar.tsx">
            <MenuIcon fontSize="small" data-sentry-element="MenuIcon" data-sentry-source-file="dashboard-userbar.tsx" />
          </IconButton>

          {/* CALL CENTER - Phone Component */}
          {isCallCenterActive && <DashboardUserbarCallCenterComponent twilioDevice={twilioDevice} />}
          <CalendarNotificationCard data-sentry-element="CalendarNotificationCard" data-sentry-source-file="dashboard-userbar.tsx" />

          <Box sx={{
          display: 'flex'
        }} data-sentry-element="Box" data-sentry-source-file="dashboard-userbar.tsx">
            {/* <ColorModeButton /> */}
            {/* <LanguageButton /> */}
            {/* <ContentSearchButton /> */}
            {/* <MessagesButton /> */}

            {/* IVR NOTIFICATION COUNTER - POP UP DIALOG */}
            {ivrCurrentCallsCounter > 0 && <IvrCount ivrCurrentCallsCounter={ivrCurrentCallsCounter} />}

            <DisplayStoreName data-sentry-element="DisplayStoreName" data-sentry-source-file="dashboard-userbar.tsx" />
            <NotificationsButton data-sentry-element="NotificationsButton" data-sentry-source-file="dashboard-userbar.tsx" />
            <UserButton data-sentry-element="UserButton" data-sentry-source-file="dashboard-userbar.tsx" />
          </Box>
        </Toolbar>
      </DashboardUserbarRoot>
      {/* <IncomingCallModal
        incomingCall={incomingCall}
        setIncomingCall={setIncomingCall}
       /> */}
    </>;
};
DashboardUserbar.propTypes = {
  onOpenSidebar: PropTypes.func
};