import useClassNames from '@/lib/hooks/useClassNames';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
const TailwindIndicator = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenOrientation, setScreenOrientation] = useState(window.screen.orientation.type);
  const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio);
  const breakpoints = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
  };
  const currentBreakpoint = Object.entries(breakpoints).reverse().find(([, value]) => screenWidth >= value)?.[0];
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      setScreenOrientation(window.screen.orientation.type);
      setPixelRatio(window.devicePixelRatio);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const tailwind_dev_bar = process.env.NEXT_PUBLIC_TAILWIND_DEV_BAR;
  return <Box sx={{
    zIndex: theme => theme.zIndex.appBar + 3000
  }} data-sentry-element="Box" data-sentry-component="TailwindIndicator" data-sentry-source-file="tailwind-indicator.tsx">
      <div className={useClassNames(tailwind_dev_bar === 'true' ? 'bg-gray-900' : 'hidden', 'fixed bottom-0 left-0 m-1 flex h-6 w-auto items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white')}>
        <div>
          <span className="mr-2">{currentBreakpoint}</span>
          <span className="mr-2">
            {screenWidth} x {screenHeight}
          </span>
          <span className="mr-2">
            Orientation:{' '}
            {screenOrientation.replace('portrait-primary', 'Portrait').replace('landscape-primary', 'Landscape')}
          </span>
          <span>Pixel Ratio: {pixelRatio}</span>
        </div>
      </div>
    </Box>;
};
export default TailwindIndicator;