import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SettingsOptimizeAccount from '@/lib/types/rtk-types/settings-optimize-account';
interface CircularWithValueLabelProps {
  storeData: SettingsOptimizeAccount;
}
const CircularProgressWithLabel: React.FC<CircularProgressProps & {
  value: number;
}> = props => {
  return <Box sx={{
    position: 'relative',
    display: 'inline-flex'
  }} data-sentry-element="Box" data-sentry-component="CircularProgressWithLabel" data-sentry-source-file="circle-progress-with-label.tsx">
      <CircularProgress color="primary" size={30} thickness={4} variant="determinate" {...props} data-sentry-element="CircularProgress" data-sentry-source-file="circle-progress-with-label.tsx" />
      <Box sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }} data-sentry-element="Box" data-sentry-source-file="circle-progress-with-label.tsx">
        <span className="inline-flex items-center gap-x-1.5 rounded-md py-1 font-mono text-xs text-white">
          {Math.round(props.value)}%
        </span>
      </Box>
    </Box>;
};
const CircularWithValueLabel: React.FC<CircularWithValueLabelProps> = ({
  storeData
}) => {
  const value = storeData?.store_getting_started_data.filter(item => item.is_complete).length / storeData?.store_getting_started_data.length * 100;
  return <CircularProgressWithLabel value={value} data-sentry-element="CircularProgressWithLabel" data-sentry-component="CircularWithValueLabel" data-sentry-source-file="circle-progress-with-label.tsx" />;
};
export default CircularWithValueLabel;