// const useAvatarColors = (digit: number) => {
//   if (digit > 0 && digit < 10) {
//     return 'bg-red-100 text-red-600 ';
//   } else if (digit > 10 && digit < 30) {
//     return 'bg-orange-100 text-orange-600 ';
//   } else if (digit > 30 && digit < 40) {
//     return 'bg-amber-100 text-amber-600 ';
//   } else if (digit > 40 && digit < 60) {
//     return 'bg-green-100 text-green-600 ';
//   } else if (digit > 60 && digit < 90) {
//     return 'bg-blue-100 text-blue-600 ';
//   } else if (digit > 90 && digit < 100) {
//     return 'bg-indigo-100 text-indigo-600 ';
//   } else {
//     return 'bg-teal-100 text-teal-600 ';
//   }
// };

// export default useAvatarColors;

const useAvatarColors = (range: number, fn: string, ln: string) => {
  if (!fn && !ln) {
    return `relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-200 p-5   font-proximaBold text-lg text-gray-500`;
  } else {
    if (range > 0 && range < 25) {
      return `relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-100 p-5   font-proximaBold text-lg text-red-500`;
    } else if (range >= 25 && range < 50) {
      return `relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-orange-100 p-5   font-proximaBold text-lg text-orange-500`;
    } else if (range >= 50 && range < 75) {
      return `relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 p-5   font-proximaBold text-lg text-yellow-500`;
    } else if (range >= 75) {
      return `relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-100 p-5   font-proximaBold text-lg text-green-500`;
    } else {
      return `relative inline-flex h-12 w-12 items-center justify-center rounded-full text-blue-500 p-5   font-proximaBold text-lg bg-blue-100`;
    }
  }
};

export default useAvatarColors;
