import { Mail } from '@/assets/icons/mail';
import { IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
interface Props {
  onMarkAllAsRead: (() => void) | undefined;
}
const NotificationHeader = (props: Props) => {
  const {
    onMarkAllAsRead
  } = props;
  return <div className="flex items-center justify-between border-b border-zinc-200 p-4 text-center text-gray-700" data-sentry-component="NotificationHeader" data-sentry-source-file="notification-header.tsx">
      <Typography color="inherit" variant="h6" data-sentry-element="Typography" data-sentry-source-file="notification-header.tsx">
        Notifications
      </Typography>
      <Tooltip title="Mark all as read" data-sentry-element="Tooltip" data-sentry-source-file="notification-header.tsx">
        <IconButton onClick={onMarkAllAsRead} size="small" color="inherit" data-sentry-element="IconButton" data-sentry-source-file="notification-header.tsx">
          <SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="notification-header.tsx">
            <Mail data-sentry-element="Mail" data-sentry-source-file="notification-header.tsx" />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </div>;
};
export default NotificationHeader;