import { useEffect, useState } from 'react';
import type { FC } from 'react';
import type { DarkMode } from '@/lib/contexts/dark-mode-context';
import { useDarkMode } from '@/lib/hooks/use-dark-mode';
import PropTypes from 'prop-types';
import { Box, ListItemIcon, ListItemText, Popover, MenuItem, Typography } from '@mui/material';
import { Sun as LightThemeIcon } from '@/assets/icons/sun';
import { Moon as DarkThemeIcon } from '@/assets/icons/moon';
interface ColorModePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}
const themes = [{
  label: 'Light',
  value: 'light',
  icon: LightThemeIcon
}, {
  label: 'Dark',
  value: 'dark',
  icon: DarkThemeIcon
}];
const getValues = (darkMode: DarkMode) => ({
  theme: darkMode.theme
});
export const ColorModePopover: FC<React.PropsWithChildren<ColorModePopoverProps>> = props => {
  const {
    anchorEl,
    onClose,
    open,
    ...other
  } = props;
  const {
    darkMode,
    saveDarkMode
  } = useDarkMode();
  const [values, setValues] = useState<DarkMode>(getValues(darkMode));
  const handleChange = (field: string, value: unknown): void => {
    setValues({
      ...values,
      [field]: value
    });
    saveDarkMode({
      ...values,
      [field]: value
    });
    onClose?.();
  };
  const [selectedTheme, setSelectedTheme] = useState(darkMode.theme);
  useEffect(() => {
    setSelectedTheme(darkMode.theme);
  }, [darkMode.theme]);
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'center',
    vertical: 'bottom'
  }} keepMounted onClose={onClose} open={!!open} PaperProps={{
    sx: {
      width: 120
    }
  }} transitionDuration={0} {...other} data-sentry-element="Popover" data-sentry-component="ColorModePopover" data-sentry-source-file="color-mode-popover.tsx">
      <Box sx={{
      alignItems: 'center',
      py: 1,
      display: 'flex'
    }} data-sentry-element="Box" data-sentry-source-file="color-mode-popover.tsx">
        <Box sx={{
        ml: 0
      }} data-sentry-element="Box" data-sentry-source-file="color-mode-popover.tsx">
          {themes.map(theme => {
          const {
            label,
            icon: Icon,
            value
          } = theme;
          return <div key={value}>
                <MenuItem onClick={() => handleChange('theme', value)}>
                  <ListItemIcon>
                    <Icon fontSize="small" sx={{
                  color: values.theme === value ? 'primary.main' : 'neutral.500'
                }} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body1" sx={{
                color: values.theme === value ? 'primary.main' : 'neutral.500'
              }}>
                        {label}
                      </Typography>} />
                </MenuItem>

                {/* <Box
                  onClick={() => handleChange('theme', value)}
                  sx={{
                    borderColor:
                      values.theme === value ? 'primary.main' : 'divider',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 2,
                    cursor: 'pointer',
                    flexGrow: 1,
                    m: 1,
                    overflow: 'hidden',
                    p: 1
                  }}
                 >
                  <Icon fontSize="small" />
                  <Typography variant="body2">{label}</Typography>
                 </Box> */}
              </div>;
        })}
        </Box>
      </Box>
    </Popover>;
};
ColorModePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};