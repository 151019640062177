import NotificationEmptyState from '@/components/_shared/layouts/dashboard/dashboard-userbar/notifications-button/notification-empty-state';
import NotificationHeader from '@/components/_shared/layouts/dashboard/dashboard-userbar/notifications-button/notification-header';
import { Scrollbar } from '@/components/_shared/scrollbar';
import { useAppSelector } from '@/lib/state/hooks';
import { Popover } from '@mui/material';
import { FC } from 'react';
import NotificationItem from '@/components/_shared/layouts/dashboard/dashboard-userbar/notifications-button/notification-item';
import { Message } from '@/lib/state/slices/notification-bell-slice';
interface NotificationsPopoverProps {
  anchorEl: null | Element;
  notifications: Message[];
  onClose?: () => void;
  onMarkAllAsRead?: () => void;
  onRemoveOne?: (id: string) => void;
  open?: boolean;
}

/* Description:

This component is used to manage the UI for displaying notifications in a popover.
It takes an anchor element, notifications array, and callback functions as props.
The component renders a list of notifications and provides options to mark all as read or remove one.
  
  */

export const NotificationsPopover: FC<NotificationsPopoverProps> = props => {
  const {
    anchorEl,
    notifications,
    onClose,
    onMarkAllAsRead,
    onRemoveOne,
    open = false,
    ...other
  } = props;
  const dataArray = useAppSelector(state => state.counter.messages);
  const isEmpty = dataArray?.length === 0;
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'left',
    vertical: 'bottom'
  }} disableScrollLock onClose={onClose} open={open} slotProps={{
    paper: {
      sx: {
        width: 380
      }
    }
  }} {...other} data-sentry-element="Popover" data-sentry-component="NotificationsPopover" data-sentry-source-file="notifications-popover.tsx">
      <NotificationHeader onMarkAllAsRead={onMarkAllAsRead} data-sentry-element="NotificationHeader" data-sentry-source-file="notifications-popover.tsx" />
      {isEmpty ? <NotificationEmptyState /> : <Scrollbar sx={{
      maxHeight: 400
    }}>
          <ul role="list" className="overflow-hidden text-base list-none bg-white divide-y divide-gray-100 rounded shadow-lg">
            {dataArray && dataArray.map((notification: Message, i: number) => {
          return <NotificationItem notification={notification} key={i} />;
        })}
          </ul>
        </Scrollbar>}
    </Popover>;
};