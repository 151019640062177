import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useEffect } from 'react';

/* Description:
 * This hook initializes the Pusher client and sets up the Echo instance.
 * It uses the token passed to it to authenticate with the server.
 * The hook is used in the _app.tsx file to initialize the Pusher client
 * when the app loads.
 */

export const usePusher = (token: string) => {
  useEffect(() => {
    // Enable logging for Pusher for development
    // Pusher.logToConsole =
    //   process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? true : false;
    try {
      //@ts-ignore
      window.Pusher = Pusher;

      if (!window.Echo || window.Echo === undefined) {
        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.NEXT_PUBLIC_PUSHER_KEY,
          cluster: 'us3',
          forceTLS: true,

          authEndpoint: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/broadcasting/allow`,
          auth: {
            headers: {
              Authorization: 'Bearer ' + token,
              'Access-Control-Allow-Origin': 'https://rmdevs.com'
            }
          }
        });
      }
    } catch (error) {
      console.error('Error creating Pusher client:', error);
    }
  }, [token]);
};
