/**
 * This is a custom hook for displaying a date and time
 * in a specific format depending on how long ago the
 * date occurred. The hook takes in a createdAtDate parameter,
 * which is the date that the item was created. It then uses
 * the dayjs library to calculate the difference between the
 * createdAtDate and the current date in minutes. Depending
 * on the length of time that has passed since the createdAtDate,
 * the hook will return a string with the date and time in a
 * specific format. For example, if the createdAtDate occurred
 * less than an hour ago, the returned string will include
 * the time it was created and the number of minutes ago it
 * occurred.
 */

import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export const useCreatedAtDate = (createdAtDate: Dayjs) => {
  const [createdTime, setCreatedTime] = useState('');

  useEffect(() => {
    // This is the date that the item was created
    const createdAt = dayjs(createdAtDate);

    // This is the current date
    const now = dayjs();

    const timeDifference = now.diff(createdAt, 'minute');

    if (timeDifference < 60) {
      // If the item was created less than an hour ago, display the sent time along with the number of minutes ago it was sent
      setCreatedTime(
        `${createdAt.format('h:mm A')} (${timeDifference} minutes ago)`
      );
    } else if (timeDifference < 1440) {
      // If the item was created less than 24 hours ago, display the sent time along with a rounded time of hours ago it was sent
      const hoursAgo = Math.round(timeDifference / 60);
      setCreatedTime(`${createdAt.format('h:mm A')} (${hoursAgo} hours ago)`);
    } else if (timeDifference > 1440 && timeDifference < 2880) {
      // If the item was created less than 24 hours ago, display the sent time along with a rounded time of hours ago it was sent
      setCreatedTime(
        `${createdAt.format('ddd, h:mm A')} (${now.diff(
          createdAt,
          'day'
        )} days ago)`
      );
    } else if (
      timeDifference > 2880 &&
      timeDifference < 7862400 &&
      createdAt.year() === now.year()
    ) {
      // If the item was created less than 24 hours ago, display the sent time along with a rounded time of hours ago it was sent
      setCreatedTime(`${createdAt.format('ddd, MMM D, h:mm A')}`);
    } else if (createdAt.year() !== now.year()) {
      // If the item was created more than 90 days ago and the year is different than the current year, display the date in the format of "MM/DD/YY"
      setCreatedTime(`${createdAt.format('ddd, MM/DD/YY, h:mm A')}`);
    } else {
      // If the item was created more than 24 hours ago, display the day of the week it was sent, along with the date, and the number of days ago it was sent
      setCreatedTime(`${createdAt.format('ddd, MM/DD/YY, h:mm A')}`);
    }
  }, [createdAtDate]);

  return createdTime;
};
