import { api } from './api';
import {
  ChatConversationResponse,
  Customer,
  CustomerData,
  CustomerTimelineItemsPaginated
} from '@/lib/types/communication-center';
import { GetTwilioTokenResponse } from '../../types/communication-center';
import { Assignment } from '@/lib/state/slices/auth-slice';

export interface TagUpdateOptions {
  activeId?: number;
  status_tag?: string;
}
export interface SendSmsOptions {
  id?: number | null;
  body?: string;
  temporary_media_uuid?: string | null;
}

export interface ToggleAutoRespondOptions {
  id: number;
  autorespond_enabled: boolean;
}

export interface SendEmailOptions {
  subject: string;
  body: string;
  id: number;
  cc_recipients?: string[] | null;
  bcc_recipients?: string[] | null;
}

export interface UpdateAgentOptions {
  activeId?: number;
  assignee_id?: number | string;
}

export interface UpdateAgentResponse {
  data?: UpdateAgentResponseData;
}

export interface UpdateAgentResponseData {
  message?: string;
  assignment?: Assignment;
}

export interface AddNoteOptions {
  body: string;
  id: number;
}

export interface ToggleAutoRespondResponse {
  data?: { autorespond_enabled: boolean };
}
export const CommunicationTheaterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<CustomerData, void>({
      query: () => 'v2/customers',
      providesTags: ['CommunicationTheaterData']
    }),

    getCustomer: build.mutation<Customer, number | undefined>({
      query: (id) => {
        return {
          url: `v2/customer_store/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: [
        'CommunicationTheaterData',
        'CommunicationTheaterDataAutorespond'
      ]
    }),
    updateStatusTag: build.mutation<Customer, TagUpdateOptions>({
      query: (data) => {
        const { activeId, ...body } = data;
        return {
          url: `v2/customer_store/update_status/${activeId}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    updateAssignedAgent: build.mutation<
      UpdateAgentResponse,
      UpdateAgentOptions
    >({
      query: (data) => {
        const { activeId, ...body } = data;
        return {
          url: `/v2/customer_store/update_assignment/${activeId}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: [
        'CommunicationTheaterData',
        'ProspectManagementOpportunitiesData',
        'ProspectsManagementProspectsData'
      ]
    }),
    sendSms: build.mutation<Customer, SendSmsOptions>({
      query: (data) => {
        return {
          url: `/v2/customer_store/send_sms`,
          method: 'POST',
          body: {
            id: data.id,
            body: data.body,
            temporary_media_uuid: data.temporary_media_uuid
          }
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    sendChatMessage: build.mutation<Customer, SendSmsOptions>({
      query: (data) => {
        return {
          url: `/v2/customer_store/send_chat_message`,
          method: 'POST',
          body: {
            id: data.id,
            body: data.body
          }
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    toggleCustomerAutoRespond: build.mutation<
      ToggleAutoRespondResponse,
      ToggleAutoRespondOptions
    >({
      query: (data) => {
        return {
          url: `/v2/customer_store/toggle_contact_auto_respond`,
          method: 'POST',
          body: {
            id: data.id,
            autorespond_enabled: data.autorespond_enabled
          }
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    sendEmail: build.mutation<Customer, SendEmailOptions>({
      query: (body) => {
        return {
          url: `/v2/customer_store/send_email`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    addNote: build.mutation<Customer, AddNoteOptions>({
      query: (data) => {
        return {
          url: `/v2/customer_store/add_note`,
          method: 'POST',
          body: { body: data.body, id: data.id }
        };
      },
      invalidatesTags: [
        'CommunicationTheaterData',
        'ProspectManagementOpportunitiesData'
      ]
    }),
    getTwilioToken: build.mutation<GetTwilioTokenResponse, void>({
      query: () => {
        return {
          url: `/v2/twilio_voice/token`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    getChatConversations: build.query<ChatConversationResponse, void>({
      query: () => {
        return {
          url: `/v2/store/chat_messages`,
          method: 'GET'
        };
      },
      providesTags: ['CommunicationTheaterData']
    }),
    getCustomerTimelineItems: build.mutation<
      CustomerTimelineItemsPaginated,
      { id: number; currentPage: number }
    >({
      query: (data) => {
        const { id, currentPage, ...body } = data;
        return {
          url: `/v2/customer_store_timeline_items/${id}?page=${currentPage}`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    }),
    getCustomersAutorespond: build.query<CustomerData, void>({
      query: () => 'v2/customers_autorespond',
      providesTags: ['CommunicationTheaterDataAutorespond']
    }),
    triggerRefetch: build.mutation<unknown, void>({
      query: () => {
        return {
          url: `/v2/store/trigger_refetch`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationTheaterData']
    })
  })
});

export const {
  useGetCustomersQuery,
  useGetCustomersAutorespondQuery,
  useToggleCustomerAutoRespondMutation,
  useGetCustomerMutation,
  useUpdateStatusTagMutation,
  useUpdateAssignedAgentMutation,
  useGetTwilioTokenMutation,
  useSendSmsMutation,
  useSendChatMessageMutation,
  useSendEmailMutation,
  useAddNoteMutation,
  useGetChatConversationsQuery,
  useGetCustomerTimelineItemsMutation,
  useTriggerRefetchMutation
} = CommunicationTheaterApi;

export interface PusherCreate {
  data?: { token: string };
}
