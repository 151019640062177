import { useState } from 'react';
import WelcomeModalFeature from '@/components/dashboard/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-feature';
import { CalendarDaysIcon, Cog8ToothIcon, RectangleGroupIcon } from '@heroicons/react/20/solid';
const WelcomeModalCalendarEvents = () => {
  const subheading = 'Efficiency Amplified';
  const title = 'Calendar Events';
  const description = 'Seamlessly merge calendar events, appointments, and reminders with your contacts, CRM, and marketing endeavors. Our intuitive calendar feature ensures all marketing activities are integrated, streamlining your schedule. Experience enhanced productivity and collaboration with our platform.';
  const imgSrc = '/assets/img/welcome-modal/calendar-events.png';
  const features = [{
    name: 'Integrated Calendar:',
    description: 'Combine calendar events, appointments, and reminders with your contacts, CRM, and marketing efforts for simplified day-to-day management.',
    icon: CalendarDaysIcon
  }, {
    name: 'Automated Tracking:',
    description: 'Automatically track and integrate all marketing activities into the calendar, ensuring nothing falls through the cracks and providing a centralized platform for management.',
    icon: Cog8ToothIcon
  }, {
    name: 'Effortless Collaboration:',
    description: 'Stay informed and aligned with upcoming tasks, marketing and deadlines, promoting enhancing productivity. Streamline communication and drive success for your business.',
    icon: RectangleGroupIcon
  }];
  const [loaded, setLoaded] = useState(false);
  return <WelcomeModalFeature setLoaded={setLoaded} subheading={subheading} title={title} description={description} features={features} imgSrc={imgSrc} data-sentry-element="WelcomeModalFeature" data-sentry-component="WelcomeModalCalendarEvents" data-sentry-source-file="welcome-modal-calendar-events.tsx" />;
};
export default WelcomeModalCalendarEvents;