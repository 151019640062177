import { UserPopover } from '@/components/_shared/layouts/dashboard/dashboard-userbar/user-button/user-popover';
import { useAuth } from '@/lib/hooks/use-auth';
import { useInitials } from '@/lib/hooks/use-initials';
import { useAppSelector } from '@/lib/state/hooks';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import { UserInterface } from '@/lib/types/user-interface';
import { ButtonBase } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
export const UserButton = () => {
  const store_id = useAuth()?.user?.store_id;
  const {
    data: storeData,
    isLoading
  } = useGetStoreSettingsQuery(store_id);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };
  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };
  useEffect(() => {
    //always close popover when user clicks outside of it or it refreshes
    handleClosePopover();
  }, [storeData]);
  const userAvatar = (useAppSelector(state => state?.auth?.user) as UserInterface);
  const user = (useAppSelector(state => state.auth.user) as UserInterface);
  const userName = (user?.name as string);
  const getInitials = useInitials(userName);
  // const avatarColors = useAvatarColors(user?.id as number);

  return <div className="" data-sentry-component="UserButton" data-sentry-source-file="user-button.tsx">
      <Box component={ButtonBase} onClick={handleOpenPopover} ref={anchorRef} sx={{
      alignItems: 'center',
      display: 'flex',
      ml: 2
    }} data-sentry-element="Box" data-sentry-source-file="user-button.tsx">
        <div className="">
          <span className={`inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 text-orange-500 `}>
            <span className="font-base font-proximaBold leading-none">
              {getInitials}
            </span>
          </span>
        </div>
      </Box>
      <UserPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} data-sentry-element="UserPopover" data-sentry-source-file="user-button.tsx" />
    </div>;
};