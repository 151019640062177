import { IconLogo } from '@/assets/logos/icon-logo';
import CustomerAvatar from '@/components/dashboard/communication-center/components/customer-card/customer-avatar';
import { useCreatedAtDate } from '@/lib/hooks/use-created-at-date';
import { useAppDispatch } from '@/lib/state/hooks';
import { useGetCustomerMutation } from '@/lib/state/services/communication-theater-api';
import { ActiveCustomer, setActiveCustomer } from '@/lib/state/slices/communication-slice';
import { Message, removeMessageNotificationBell } from '@/lib/state/slices/notification-bell-slice';
import { Customer } from '@/lib/types/communication-center';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';
import { Str } from '@supercharge/strings';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
type Props = {
  notification: Message;
};

/** Description:
 * This component is used to manage the UI for a single notification item.
 * It takes a notification object as a prop and renders the appropriate UI based on the notification data.
 * The component also updates the Redux store with active customer data.
 *
 */

const NotificationItem: React.FC<Props> = ({
  notification
}) => {
  const [getActiveCustomer] = useGetCustomerMutation();
  const formattedDate = useCreatedAtDate(dayjs(notification?.sent_at));
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [customer, setCustomer] = useState<Customer | null>(null);
  useEffect(() => {
    const getCustomer = async () => {
      if (notification?.customer_store_id) {
        const res = (await getActiveCustomer(notification.customer_store_id) as {
          data?: ActiveCustomer;
        });
        const responseObject = res.data?.customer;
        setCustomer(responseObject || null);
      }
    };
    getCustomer();
  }, [notification?.customer_store_id, getActiveCustomer]);
  const handleRouting = () => {
    if (customer) {
      dispatch(setActiveCustomer({
        customer: customer
      }));
      router.push(`/dashboard/all-contacts/${customer.id}`);
    } else {
      router.push('/dashboard/communication-center');
    }
    dispatch(removeMessageNotificationBell(notification.id));
  };
  const handleMarkAsRead = () => {
    dispatch(removeMessageNotificationBell(notification.id));
  };
  return <div className="relative px-4 py-3 border-b hover:bg-gray-100" data-sentry-component="NotificationItem" data-sentry-source-file="notification-item.tsx">
      <div onClick={handleRouting} className="flex">
        <div className="shrink-0">
          {notification?.customer_store_id ? <CustomerAvatar firstName={customer?.first_name} lastName={customer?.last_name} id={customer?.id} avg_spend_per_visit_for_store_range={customer?.avg_spend_per_visit_for_store_range} /> : <div className="rounded-full bg-orange-100 p-1.5">
              <IconLogo variant="light" sx={{
            height: 38,
            width: 38
          }} />
            </div>}
        </div>
        <div className="w-full pl-2">
          <div className="mb-1.5 flex flex-col">
            <span className="text-gray-900 font-proximaSemiBold">
              {notification?.title && Str(notification?.title).limit(32, '...').get()}
            </span>
            <span className="text-sm text-gray-500 font-proximaNova">
              {notification?.body}
            </span>
          </div>
          <div className="text-xs text-orange-500 font-proximaNova">
            {formattedDate}
          </div>
        </div>
      </div>
      <button className="absolute right-3 top-3 rounded-full p-0.5 hover:bg-orange-100" onClick={handleMarkAsRead}>
        <Tooltip title="Mark as Read" data-sentry-element="Tooltip" data-sentry-source-file="notification-item.tsx">
          <XMarkIcon className="w-5 h-5 text-orange-500" data-sentry-element="XMarkIcon" data-sentry-source-file="notification-item.tsx" />
        </Tooltip>
      </button>
    </div>;
};
export default NotificationItem;