import { FC, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Favicon from 'react-favicon';
import { useAppSelector } from '@/lib/state/hooks';
import usePageType from '@/lib/hooks/use-settings-onboarding-page-type';
interface HeadTitleProps {
  headTitle: string;
  description?: string;
}
export const HeadTitle: FC<React.PropsWithChildren<HeadTitleProps>> = props => {
  const {
    headTitle,
    description
  } = props;
  const router = useRouter();
  const url = process.env.NEXT_PUBLIC_APP_URL;
  const [title, setTitle] = useState(headTitle);
  const notificationCount = useAppSelector(state => state.counter.value);
  const pageType = usePageType();
  const prevNotificationCountRef = useRef(notificationCount);
  const isInitialLoadRef = useRef(true);
  useEffect(() => {
    if (notificationCount > 0) {
      const originalTitle = headTitle;
      const updateTitle = () => {
        setTitle(prev => prev === originalTitle ? `(${notificationCount} New Alerts!) ${originalTitle}` : originalTitle);
      };
      const intervalId = setInterval(updateTitle, 1000);
      return () => {
        clearInterval(intervalId);
        setTitle(originalTitle);
      };
    }
  }, [notificationCount, headTitle]);
  useEffect(() => {
    // Update theme-color meta tag
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', notificationCount > 0 ? '#FF0000' : '#FFFFFF');
    }

    // Play sound alert on initial load if there are unread notifications
    // or when a new notification comes in
    //TODO: add sound back when the notifications are fixed
    // if (
    //   pageType !== 'optimize' &&
    //   pageType !== 'onboarding' &&
    //   ((isInitialLoadRef.current && notificationCount > 0) ||
    //     (!isInitialLoadRef.current &&
    //       notificationCount > prevNotificationCountRef.current))
    // ) {
    //   const audio = new Audio('/assets/audio/chat-notification.mp3');
    //   audio.play();
    // }

    // Update the previous notification count
    prevNotificationCountRef.current = notificationCount;
    isInitialLoadRef.current = false;

    // Request notification permission
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, [notificationCount, pageType]);
  const sendNotification = () => {
    if (Notification.permission === 'granted' && notificationCount > 0) {
      new Notification('New Alert!', {
        body: `You have ${notificationCount} new notification(s) in your dashboard.`,
        icon: '/favicon/favicon-32x32.png'
      });
    }
  };

  // Call sendNotification when appropriate (e.g., when notificationCount changes)
  useEffect(() => {
    if (notificationCount > prevNotificationCountRef.current) {
      sendNotification();
    }
  }, [notificationCount]);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="head-title.tsx">
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <link rel="canonical" href={`${url}${router.asPath}`} />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="theme-color" content={notificationCount > 0 ? '#FF0000' : '#FFFFFF'} data-sentry-element="meta" data-sentry-source-file="head-title.tsx" />
      </Head>
      <Favicon url={['/favicon/favicon.ico']} alertCount={notificationCount} data-sentry-element="Favicon" data-sentry-source-file="head-title.tsx" />
    </>;
};
HeadTitle.propTypes = {
  headTitle: PropTypes.string.isRequired,
  description: PropTypes.string
};