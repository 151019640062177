import Image from 'next/image';
import React from 'react';
interface Props {
  setLoaded: (value: boolean) => void;
  subheading: string;
  title: string;
  description: string;
  imgSrc: string;
  features: {
    name: string;
    description: string;
    icon: React.ElementType;
  }[];
}
const WelcomeModalFeature = ({
  setLoaded,
  subheading,
  title,
  description,
  imgSrc,
  features
}: Props) => {
  return <div className="overflow-hidden rounded-t-xl bg-[#f9fafb]" data-sentry-component="WelcomeModalFeature" data-sentry-source-file="welcome-modal-feature.tsx">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 ">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-orange-600">
                {subheading}
              </h2>
              <p className="mt-2 font-proximaBold text-3xl tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {description}
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map(feature => <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-proximaBold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-orange-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>)}
              </dl>
            </div>
          </div>
          <Image src={imgSrc} alt="Prospect Management Page" width={2432} height={1442} priority onLoadingComplete={() => setLoaded(true)} className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" data-sentry-element="Image" data-sentry-source-file="welcome-modal-feature.tsx" />
        </div>
      </div>
    </div>;
};
export default WelcomeModalFeature;