import type { FC } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography } from '@mui/material';
// import { getContacts } from '@/lib/state/slices/communication-center-slice';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import { StatusIndicator } from '@/components/_shared/status-indicator';
interface MessagesPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}
export const MessagesPopover: FC<React.PropsWithChildren<MessagesPopoverProps>> = props => {
  const {
    anchorEl,
    onClose,
    open,
    ...other
  } = props;
  const dispatch = useAppDispatch();
  const {
    contacts
  } = useAppSelector(state => state.communicationCenter);

  // useEffect(
  //   () => {
  //     dispatch(getContacts());
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'center',
    vertical: 'bottom'
  }} onClose={onClose} open={!!open} PaperProps={{
    sx: {
      p: 2,
      width: 320
    }
  }} transitionDuration={0} {...other} data-sentry-element="Popover" data-sentry-component="MessagesPopover" data-sentry-source-file="messages-popover.tsx">
      <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="messages-popover.tsx">Messages</Typography>
      <Box sx={{
      mt: 2
    }} data-sentry-element="Box" data-sentry-source-file="messages-popover.tsx">
        <List disablePadding data-sentry-element="List" data-sentry-source-file="messages-popover.tsx">
          {contacts.allIds.map((contactId: string) => {
          const contact = contacts.byId[contactId];
          return <ListItem disableGutters key={contact.id}>
                <ListItemAvatar>
                  <Avatar src={contact.avatar} sx={{
                cursor: 'pointer'
              }} />
                </ListItemAvatar>
                <ListItemText disableTypography primary={<Link color="textPrimary" noWrap sx={{
              cursor: 'pointer'
            }} underline="none" variant="subtitle2">
                      {contact.name}
                    </Link>} />
                {contact.isActive ? <StatusIndicator size="small" status="online" /> : contact.lastActivity && <Typography color="textSecondary" noWrap variant="caption">
                      {formatDistanceToNowStrict(contact.lastActivity)} ago
                    </Typography>}
              </ListItem>;
        })}
        </List>
      </Box>
    </Popover>;
};
MessagesPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};