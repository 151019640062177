import { useState, useEffect } from 'react';

export const useInitials = (name: string) => {
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    const getInitials = (name: string) => {
      name = name?.trim();

      if (name?.length <= 3) return name;
      if (!name) {
        return '';
      }

      return name
        .split(/\s+/)
        .map((w) => [...w][0])
        .slice(0, 3)
        .join('');
    };

    setInitials(getInitials(name));
  }, [name]);

  return initials;
};
