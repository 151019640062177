import React, { useState } from 'react';
import { Device } from '@twilio/voice-sdk';
import { useAppSelector, useAppDispatch } from '@/lib/state/hooks';
import { updateIsCallActive, updateOutboundCallStatus } from '@/lib/state/slices/call-center-slice';
import { MuteIcon } from '@/assets/icons/mute-icon';
import { CallPhoneIcon } from '@/assets/icons/call-phone-icon';
import { EndCallIcon } from '@/assets/icons/end-call-icon';
import { Str } from '@supercharge/strings';
import { reformatPhoneNumber } from '@/lib/hooks/use-reformat-phone-number';
import { useCallManagement } from '@/lib/hooks/use-call-management';
type IncomingOutgoingActiveCallProps = {
  twilioDevice: Device | null;
};

/**
 * IncomingOutgoingActiveCallComponent
 *
 * This component manages the UI for incoming, outgoing, and active calls in the call center application.
 * It uses the useCallManagement hook for call handling logic and interacts with Redux for global state.
 *
 * Key features:
 * - Renders different UIs based on call stage (incoming, outgoing, active)
 * - Handles user interactions for accepting, ending, and muting calls
 * - Integrates with the useCallManagement hook for call control
 * - Uses Redux for accessing global call state
 *
 * Props:
 * - twilioDevice: Twilio Device instance
 *
 * State:
 * - muteStatus: Local state for tracking call mute status
 *
 * Key functions:
 * - handleAcceptCall: Accepts an incoming call
 * - handleEndCall: Ends the current call
 * - handleMuteClick: Toggles call mute status
 *
 * This component provides a user-friendly interface for managing calls,
 * ensuring seamless interaction between the user and the call center system.
 */

const IncomingOutgoingActiveCallComponent: React.FC<IncomingOutgoingActiveCallProps> = ({
  twilioDevice
}) => {
  // Local state for mute status
  const [muteStatus, setMuteStatus] = useState(false);

  // Use our custom hook for call management
  const {
    acceptCall,
    endCall,
    muteCall,
    callStage
  } = useCallManagement(twilioDevice);

  // Redux hooks
  const dispatch = useAppDispatch();

  // Redux state selectors
  const incomingCallData = useAppSelector(state => state.callCenter.incomingCallData);
  const outboundCallData = useAppSelector(state => state.callCenter.outboundCallData);
  const isOutboundCall = useAppSelector(state => state.callCenter.isOutboundCall);
  const outboundCallStatus = useAppSelector(state => state.callCenter.outboundCallStatus);

  // phone number for the call
  const phone_number = outboundCallData?.phone_number ?? incomingCallData?.phone_number;

  // call placeholder data
  const callPlaceholder = {
    name: incomingCallData?.name ?? outboundCallData?.name,
    phone_number: phone_number ?? '',
    call_status: incomingCallData?.status ?? outboundCallStatus
  };

  // Custom hook function - handle accept call
  const handleAcceptCall = async () => {
    if (incomingCallData) {
      await acceptCall(incomingCallData);
      dispatch(updateIsCallActive(true));
      dispatch(updateOutboundCallStatus('connected'));
    }
  };

  // Custom hook function - handle mute click
  const handleMuteClick = () => {
    muteCall(!muteStatus);
    setMuteStatus(!muteStatus);
  };

  // Custom hook function - handle end call
  const handleEndCall = () => {
    endCall();
  };
  const renderIncomingCallUI = () => <div className="flex items-center p-1 px-6 text-center text-white bg-orange-500 border-2 border-white rounded-lg" data-sentry-component="renderIncomingCallUI" data-sentry-source-file="incoming-outgoing-active-call-components.tsx">
      <div className="flex px-6 space-x-4">
        <div className="font-proximaBold drop-shadow-lg">
          <span>Incoming Call:</span>
          <span className="pl-2">
            {Str(callPlaceholder.name).limit(20, '...').get()}
          </span>
        </div>
        <div>{reformatPhoneNumber(callPlaceholder.phone_number)}</div>
      </div>
      <div className="flex items-center space-x-3">
        <button onClick={handleAcceptCall} className="flex items-center justify-center bg-green-500 border border-white rounded-full h-7 w-7">
          <CallPhoneIcon className="w-4 h-4" data-sentry-element="CallPhoneIcon" data-sentry-source-file="incoming-outgoing-active-call-components.tsx" />
        </button>
        <button onClick={handleEndCall} className="flex items-center justify-center w-8 h-8 text-white bg-red-600 border border-white rounded-full">
          <EndCallIcon className="w-5 h-5" data-sentry-element="EndCallIcon" data-sentry-source-file="incoming-outgoing-active-call-components.tsx" />
        </button>
      </div>
    </div>;
  const renderActiveCallUI = () => <div className="flex items-center p-1 px-6 text-center text-black border-2 border-green-500 rounded-lg" data-sentry-component="renderActiveCallUI" data-sentry-source-file="incoming-outgoing-active-call-components.tsx">
      <div className="flex space-x-3">
        <button onClick={handleMuteClick} className={`flex h-6 w-6 items-center justify-center rounded-full border-2 ${muteStatus ? 'bg-gray-400 text-white' : 'bg-white text-gray-400'}`}>
          <MuteIcon className="w-3 h-3" data-sentry-element="MuteIcon" data-sentry-source-file="incoming-outgoing-active-call-components.tsx" />
        </button>
      </div>
      <div className="flex px-6 space-x-4">
        <div className="font-proximaBold drop-shadow-lg">
          <span className={callStage === 'outgoing' ? 'text-yellow-600' : 'text-green-600'}>
            {callStage === 'outgoing' ? 'Calling:' : 'On Call:'}
          </span>
          <span className="pl-2">
            {Str(callPlaceholder.name).limit(20, '...').get()}
          </span>
        </div>
        <div>{reformatPhoneNumber(callPlaceholder.phone_number)}</div>
      </div>
      <div className="flex items-center space-x-3">
        <button onClick={handleEndCall} className="flex items-center justify-center w-8 h-8 text-white bg-red-600 border border-white rounded-full">
          <EndCallIcon className="w-5 h-5" data-sentry-element="EndCallIcon" data-sentry-source-file="incoming-outgoing-active-call-components.tsx" />
        </button>
      </div>
    </div>;
  return <div data-sentry-component="IncomingOutgoingActiveCallComponent" data-sentry-source-file="incoming-outgoing-active-call-components.tsx">
      {callStage === 'incoming' && !isOutboundCall && renderIncomingCallUI()}
      {(callStage === 'outgoing' || callStage === 'active' || isOutboundCall) && renderActiveCallUI()}
    </div>;
};
export default IncomingOutgoingActiveCallComponent;