import { useAuth } from '@/lib/hooks/use-auth';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useUserback } from '@userback/react';
function UserbackUserPopoverSuggestion({
  onClose
}: {
  onClose: () => void;
}) {
  const userback = useUserback();
  const store_id = useAuth()?.user?.store_id;
  const {
    data: storeData
  } = useGetStoreSettingsQuery(store_id);
  const user = useAuth()?.user;
  const storeName = storeData?.data?.name ?? 'Store Name';
  if (!user?.email) {
    return null;
  }
  return <>
      {user.email && <MenuItem className="group" onClick={() => {
      onClose?.();
      userback.identify(user.id.toString(), {
        name: user.name || '',
        email: user.email || '',
        account_id: user.store_id
      });
      userback.setData({
        Store_Name: storeName
      });
      userback.open();
    }}>
          <ListItemIcon>
            <div className="text-gray-600 group-hover:text-orange-500">
              <LightBulbIcon className="h-5 w-5" />
            </div>
          </ListItemIcon>
          <ListItemText primary={<p className="text-sm group-hover:text-orange-500">
                Share Feedback
              </p>} />
        </MenuItem>}
    </>;
}
export default UserbackUserPopoverSuggestion;