import React from 'react';
const NotificationEmptyState = () => {
  return <div className="pt-4 pb-8 text-center" data-sentry-component="NotificationEmptyState" data-sentry-source-file="notification-empty-state.tsx">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto h-12 w-12 text-gray-400" data-sentry-element="svg" data-sentry-source-file="notification-empty-state.tsx">
        <path fillRule="evenodd" d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="notification-empty-state.tsx" />
      </svg>

      <h2 className="mt-2 text-base font-semibold leading-6 text-gray-400">
        No unread notifications
      </h2>
    </div>;
};
export default NotificationEmptyState;